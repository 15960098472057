<template>
  <default-layout>
    <div class="container project--partner" v-if="projectPartner">
      <project-partner-header :projectPartner="projectPartner"></project-partner-header>
      <div class="project--partner-content">
        <div class="row" v-if="projectPartner">
          <div class="col-12 col-lg-9">
            <Overview
              :developer="projectPartner.developer_name"
              :price="projectPartner.min_price"
              :type="projectPartner.property_type"
            />
            <client-only>
              <DescProperty :description="projectPartner.description" />
            </client-only>
            <Youtube :youtube_url="projectPartner.youtube_url" :use-header="false"></Youtube>
            <Detail
              :start="null"
              :finish="
                projectPartner.done_year_est ? projectPartner.done_year_est.toString() : null
              "
              :sizeArea="projectPartner.project_area_size"
              :totalFloor="projectPartner.total_floor ? projectPartner.total_floor.toString() : ''"
              :type="projectPartner.type"
              :typeCount="projectPartner.listings ? projectPartner.listings.length.toString() : '0'"
            />
            <client-only>
              <ProjectListing
                v-if="projectPartner.listings"
                :listings="projectPartner.listings"
                :brosur="null"
                :projectPartner="projectPartner"
              />
            </client-only>
          </div>
          <ActionSection
            :developer_image="projectPartner.developer_picture_url"
            :developer_web="null"
            :name="projectPartner.developer_name"
            :call="projectPartner.call"
            :number="projectPartner.developer_phone"
          />
        </div>
        <Maps
          v-if="projectPartner"
          :amenities="projectPartner.amenities"
          :google_map_url="projectPartner.google_map_url"
        />
      </div>
    </div>
  </default-layout>
</template>

<script>
const ProjectPartnerHeader = () => import('@/components/project-partner/header');
const Overview = () => import('@/components/project-partner/overview-property');
const DescProperty = () => import('@/components/project-partner/desc-property');
const Detail = () => import('@/components/project-partner/detail-property');
const ProjectListing = () => import('@/components/project-partner/project-listing');
const Maps = () => import('@/components/project-partner/maps-property');
const ActionSection = () => import('@/components/project-partner/action');
const Youtube = () => import('@/components/project-partner/youtube');
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    ProjectPartnerHeader,
    Overview,
    DescProperty,
    Detail,
    ProjectListing,
    Maps,
    ActionSection,
    Youtube,
  },
  async fetch({ store, params, route, error }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    try {
      await store.dispatch('v2/projectPartner/getProjectPartnerDetail', params.hashId);
    } catch (e) {
      console.log('ERROR GET PROJECT PARTNER DETAIL: ', e);
      error(new Error(e.message), 404);
    }

    const baseUrl = store.state.global.baseUrl;
    const project = store.state.v2.projectPartner.projectPartner;

    if (!project) {
      error(new Error('Page does not exist!'), 404);
    }

    const regex = /(<([^>]+)>)/gi;
    const projectName = project.project_name;
    const projectDesc = project.description ? project.description.replace(regex, '') : '';
    const projectAddress = project.project_address;
    const ogImage = project.developer_picture_url;

    let title = projectName + ' | Project Partner Rentfix';

    let description = 'Rentfix.com - ' + projectAddress + (projectDesc ? ' | ' + projectDesc : '');
    if (description.length > 310) description = description.substring(0, 307) + '...';
    description += ' | Rentfix';

    const head = {
      title: title,
      meta: [
        { hid: 'og-url', property: 'og:url', content: baseUrl + route.fullPath },
        { hid: 'og-type', property: 'og:type', content: 'article' },
        {
          hid: 'og-title',
          property: 'og:title',
          content: title,
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: description,
        },
        { hid: 'og-site-name', property: 'og:site_name', content: 'Rentfix' },
        {
          hid: 'og-image',
          property: 'og:image',
          content: ogImage,
        },
        { hid: 'og-image-width', property: 'og:image:width', content: '450' },
        { hid: 'og-image-height', property: 'og:image:height', content: '450' },
        { hid: 'description', name: 'description', content: description },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  computed: {
    ...mapState({
      projectPartner: (state) => state.v2.projectPartner.projectPartner,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.asd'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  mounted() {},
};
</script>
